#project-main {
  background:  #444;
  /* background: #666  url('../img/backgroundA.jpg'); */
  background: transparent  url('../img/projects-black.PNG');
  background: transparent  url('../img/lp.jpg');
  background-attachment: fixed;
  background-blend-mode: overlay;
  background-position:center center;
  height:100%;
  background-size: cover;
  width:100vw;
  margin:auto;
}
#project-section {
  margin:auto;
  height:100%;
}

.projImg {
  margin:auto;
}
.projImg img {
  width:640px;
  border-radius:10px;
  margin:auto;
}
.projImg2 img {
  width:200px;
  border-radius:10px;
  display:flex;
}
.project {
  color:#000;
  font-size: 24px;
  margin:auto;
  display: flex;
  flex: 1 1;
  align-items: stretch;
}
.summary {

  padding:20px;
  /* background:rgba(248, 246, 246, 0.4) ; */
  background:transparent;
  border-radius:10px;
  max-width:640px;
  flex:1 1;
  align-items: stretch;
  margin:auto;
}
button {
  width:90px;
  padding:5px 0;
  margin:auto;
  border-radius:10px;

}
.summary p {
  color:#fff;
  margin:10px auto;
}
button a {
  text-decoration: none;
  color:#fff;
  font-weight:bold;
}
.summary button {
  /* min-width: 100px; */
  padding: 5px;
  margin-right:10px;
  border-radius: 10px;
}
button.livelink {
  margin-top:10px;
}
.projectTitle {
  color:#eee;
  font-size:1.8vw;
  margin:80px auto 20px;
}
.projects p {
  color:#000;
}

.techStack {
  margin-top:10px;
  display:flex;
  border-top:2px #000 solid;
  padding:5px 0;
}
.plantObject {
  margin:auto;
  border:10px #fff solid;
}
h2 {
  color:white;
  font-size:2.7vw;
  text-align:center;

}
#project-section h2 {
  color:white;
  font-size:3vw;
  text-align:center;
}
/* h3 {
  color:#000;
  font-size:2vw;
  text-align:center;

} */
h4 {
  color:#fff;
  font-size:1.5vw;
  margin:10px auto;
}
p {
  font-size: 1vw;
  text-align:left;
  color:#000;
}
ul {
  -webkit-padding-start:20px;
          padding-inline-start:20px;

}
li {
  padding:0px 15px;
}
.bold {
  font-weight:bold;
}
.summary {
  max-width:640px;
  flex: 1 1;
  display: flex;
  align-items: stretch;
}
.summary p {
  font-size:1.2vw;
}
@media screen and (max-width: 1366px){
  .summary p {
    font-size:1.8vw;
  }
  
  .project-article {
    max-height:975px;
  }
}
@media screen and (max-width: 1280px){
  .projectTitle {
    font-size:2vw;

  }
  .summary p {
    font-size:1.8vw;
  }
  .project-main {
    height:3000px;
  }
  .project-article {
    max-height:1075px;
  }
  main, article {
    flex-direction: column;
  }
  .summary p {
    font-size:2.1vw;
  }
}
@media screen and (max-width: 1024px){
  .projectTitle {
    font-size:2.6vw;
  }
  main, article{
    flex-direction: column;

  }

  .summary p {
    font-size:2.3vw;
  }


}
@media screen and (max-width: 1024px) and (max-height: 1366px){

}
@media screen and (max-width: 768px){

#project-section h2 {
  color: white;
  font-size: 3.5vw;
  text-align: center;
}

  .projects img {
    width:89vw;
    border-radius:10px;

  }
}
@media screen and (max-width: 640px){

  .projImg img{
    width:100%;
    margin:auto;
  }
}
@media screen and (max-width: 480px) and (max-height: 853px){


  #project-section h2 {
      font-size: 4.5vw;
  }
  h3.projectTitle {
    font-size: 4.0vw;
  }
  .summary p {
    font-size: 3.4vw;
    /* align-self: auto; */
  }
  button a {
    font-size: 3vw;
  }

}
