* {
  box-sizing: border-box;
  font-family: sans-serif;
  margin: 0;
}
#contact-main {
  /* background: rgba(204, 204, 204, 0.8)  url('../img/pipes.jpg'); */

  background: transparent  url('../img/background.gif');
  background: transparent url('../img/lp2.jpg');
  background-blend-mode: screen;
  background-position:center center;
  background-size: cover;
  height:1080px;
  max-height:1366px;
  width: 100%;
  display:flex;
}

#contact-section {
  width:70%;
  margin:auto;
}
#contact-article {
  width:80%;
  margin:auto;
  
}
.placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  padding:0 5px;
}

.contact-form input {
  width:90%;

  font-size:1.5vw;
  display:block;
  margin:20px auto auto;
}
.contact-form textarea {

font-size:1.5vw;
width:90%;
height:150px;
display:block;
margin:auto;
}


.contacts button {
  font-size:1vw;
}
.contacts li a img {
  width:40px;
  height:40px;
}


#contact-article {

  color: #fff;
  font-size: 50px;
  margin: auto;
  text-align:center;
  border-bottom: none;
  background:rgba(255, 255, 255, 0.1) ;
  border-radius:15px;
}
#contact-article p {
  font-size:3vw;
  color:#fff;
  display: flex;
  flex-wrap: wrap;
  text-align:center;
  margin:auto;
}

.contact-main h2 {
  color:#fff;

}
.contact a {

}

.contact {
  display:flex;
  align-items: center;
  justify-content: center;
}
.contact input {
  height:35px;
  width:90%;
  display:flex;
  flex-direction: column;
}
.contact textarea {
  height:35px;
  width:90%;
  height:150px;
  display:flex;
  flex-direction: column;
}
.contacts {
  list-style-type: none;
  padding:0;
  display: flex;
  margin-top:20px;
}
.contacts button {
  display:flex;
}
.contacts li {
justify-content: space-between;
margin:auto;
}
button {
  min-width:100px;
  padding:10px 5px;
  margin:auto;
  border-radius:10px;

}
button a {
  text-decoration: none;
  color:#000;
  font-weight:bold;

}
@media screen and (max-width: 1024px){
  #contact-main {
    height: 1366px;
    display:flex;
  }
}
@media screen and (max-width: 768px){

  #contact-main {
    max-height: 1024px;
    display:flex;
  }
  #contact-article p {
    font-size:4.5vw;
  }

  #contact-article {
    margin:20% auto;
    width:100%;
    display:flex;
    height:100%;
  }
  .contact-form input {
    height: 35px;
    width: 90%;
    display: block;
    margin: 20px auto auto;
    font-size:2.5vw;
}

.contact-form textarea {
  height:35px;
  width:90%;
  height:300px;
  display:block;
  margin:auto;
  font-size:2.5vw;

}

ul.contacts {
  list-style-type: none;
  padding:0;
  display: block;
  width:100%;
  margin:auto;
  display:flex;
  align-items: flex-start 
}
.contacts li {

  padding:5px;
}
h3.contactMe {
  color:#ffffff;
  font-size:4.5vw;
}

.contacts li a{
  text-decoration:none;
}
.contacts li a, .contacts li a:visited{
color:#000;
font-size:1.5vw;
margin:auto;
}
#contact-section {
/* 
  align-items: center;
  justify-content: center; */
}
#contact-article {
  color:#000;
  /* padding:20px; */
}
#contact-article  h2 {
  color:#000;
}

.contacts button a{
  font-size:3vw;
}
h3.contactMe {
  color:#ffffff;
  font-size:4.5vw;
}
}
@media screen and (max-width: 480px) and (max-height: 853px) {

  #contact-main {
    max-height: 853px;
    display:flex;
  }
  #contact-article p {
    font-size:4.5vw;
  }

  #contact-article {
    margin:auto;
    width:500px;
    display:flex;
    height:100%;
  }

  .contact-form input {

    height:35px;
    width:90%;
    display:block;
    margin:20px auto auto;

  }
.contact-form textarea {
  height:35px;
  width:90%;
  height:150px;
  display:block;
  margin:auto;
}

.contacts {
  list-style-type: none;
  padding:0;
  display: flex;
}


.contacts li a{
  text-decoration:none;
}
.contacts li a, .contacts li a:visited{
color:#000;
width:400px;
}
#contact-section {

  margin:auto;
}
#contact-article {
  color:#000;
  /* padding:20px; */
}
#contact-article  h2 {
  color:#000;
}

}

@media screen and (max-width: 415px){
  #contact-main {
    max-height:736px;
  }
  #contact-section {
    width:80%;
    margin:auto;
  }
  #contact-article {
    margin: auto;
    width: 100%;
    display: flex;
    
}

}
@media screen and (max-width: 414px) and (max-height: 736px){
  #contact-main {
   max-height:736px;
  }
}
@media screen and (max-width: 375px) and (max-height: 812px){
  #contact-main {
      max-height:812px;
    }
    #contact-section {
      width:80%;
      margin:auto;
    }
    #contact-article {
      margin: auto;
      width: 100%;
      display: flex;
      
  }
  
    }
@media screen and (max-width: 375px) and (max-height: 667px){
 #contact-main {
    max-height:667;
  }
}

@media screen and (max-width: 375px) and (max-height:667px){
  #contact-main {
     max-height:667px;
   }
   #contact-section {
     width:80%;
     margin:auto;
   }
   #contact-article {
     margin: auto;
     width: 100%;
     display: flex;
 }

 }
 @media screen and (max-width: 360px){
 #contact-main {
    max-height:740px;
  }
  #contact-section {
    width:80%;
    margin:auto;
  }
  #contact-article {
    margin: auto;
    width: 100%;
    display: flex;
    
}

}
@media screen and (max-width: 360px)and (max-height: 640px){
  #contact-main {
     max-height:640px;
   }
   #contact-section {
     width:80%;
     margin:auto;
   }
   #contact-article {
     margin: auto;
     width: 100%;
     display: flex;     
 }
  .contacts li {
    padding:0px;
  }
   ul.contacts {
   width:100%;
   }
 }
@media screen and (max-width: 320px){
 #contact-main {
    max-height:568px;
  }
  #contact-section {
    width:280px;
    margin:auto;
  }
  #contact-article {
    margin: auto;
    width: 100%;
    display: flex;
    height: 100%;
}

}