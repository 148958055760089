* {
  box-sizing: border-box;
  font-family: sans-serif;
  margin: 0;
}

header {
  padding: 60px;
  text-align: center;
  background-color: transparent;
  color: #fff;
}


#home-section {
  display:flex;
  height: 1366px;
  }


#home-article {
  color: #fff;
  font-size: 3vw;
  margin: auto;
  text-align:center;
  border-bottom: none;

}
#home-article p {
  font-size:2.5vw;
  color:#fff;
  display: flex;
  flex-wrap: wrap;
  text-align:center;
}

footer {
  text-align: center;
  padding: 20px;
  background-color: #aaa;
}
.home-h2 {
  font-size: 2.7vw;
  color:#fff;
  text-align:center;
}
.home-h3 {
  font-size: 35px;
  margin-top:20px;
  color:#fff;
  text-align:center;
}
.home-p {
  font-size: 20px;
  margin:20px auto;
  text-align:center;
}

.links {
  margin:auto;
}



.home-contacts img {
  width:35px;
}
#home-article {
  color: #fff;
  font-size: 3vw;
  margin: auto;
  text-align: center;
  border-bottom: none;
  width: 60%;
}
@media (min-width: 1366px){
  #home-section {
    /* height:200px; */
    display:flex;
  }
  #home-article {
    color: #fff;
    font-size: 3vw;
    margin: auto;
    text-align:center;
    border-bottom: none;
    width:60%;
  }
  #home-article p {
    font-size:2.2vw;
    color:#fff;
    display: flex;
    flex-wrap: wrap;
    text-align:center;
  }

}
@media (min-width: 1024px){
  .home p {
    font-size: 20px;
    margin:20px auto;
    text-align:center;
  }

  .home  {
    color: #fff;
    font-size: 50px;
    margin: auto;
    text-align:center;
    border-bottom: none;
  }

}

.home-contact1,.home-contact2, .home-contact3 {
  padding: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
  margin: 10px;
  flex: 1;
 
  list-style-type: none;
  padding:0;
}
.home-contact1 img {
  flex: 1;
}
.home-contact2 img {
  flex: 1;
}
.home-contact3 {
  flex: 1;
}
.home-contacts {
  text-decoration:none;
  text-align:center; 
  margin:auto;
  padding-inline-start:0;
  display:flex;
}
.home-contacts li a, .home-contacts li a:visited{
color:#fff;
text-align:left;
}


.links {
  margin:auto;
}
.email {
  margin-top:10px;
}
@media screen and (max-width: 768px){
  /* #home-main {
    height:1200px;
  } */
  #home-section  {
    height:1024px;
  }
  .links {
    margin:auto;
 }
  .home {
    margin:auto;
  }
  .home-h2 {
    font-size: 40px;
  }
  .home-h3 {
    font-size: 30px;
    margin-top:20px;
  }
  .home-p {
    font-size: 20px;
    margin:20px auto;
  }
  .home-contact3 {
    margin-left: 0px;
  }
  a {
    font-size:2.5vw;
  }

  nav {
    opacity: 0.5;
  }

  .home-main  {
    color: #fff;
    font-size: 50px;
    text-align:center;
    border-bottom: none;
    margin:auto;
  }

  .home-contacts img {
    width:35px;
  }
  
  p {
    text-decoration: none;
  }
  #home-article {
    width:75%;
  }
  #home-article p {
    font-size:4.5vw;

  }
}
@media screen and (max-width: 480px) and (max-height: 853px){ 
  #home-section  {
    height:853px;
  }

  .home-contacts img {
    width:35px;
  }
  
  p {
    text-decoration: none;
  }
  #home-article {
    width:75%;
  }
  #home-article p {
    font-size:4.5vw;

  }

}
