* {
  box-sizing: border-box;
  font-family: sans-serif;
  margin: 0;
}
body {
  /* background: #2235e6 url('../img/conduit.jpg'); */
  background: #000 url('../img/_DSC0041.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* background-blend-mode: overlay; */
  background-position: -10% -100px;
  height:100%;
  width:100vw;
  margin:auto;
}
header {
  padding: 60px;
  text-align: center;
  background-color: transparent;
  color: #fff;
}

nav {
  /* display: flex; */
  background-color: #333;
}

nav a {
  color: white;
  text-decoration: none;
  margin: 20px;
}

nav a:hover {
  color: #333;
  background-color: #fff;
}


main {
  display: flex;
  flex-wrap: wrap;
}

article {
  border-bottom: 2px solid #999;
  padding: 10px;
  margin-bottom: 20px;
}

footer {
  text-align: center;
  padding: 20px;
  background-color: #aaa;
}
/* .Navbar__menu {
  display: none;
} */

#Navbar_links {
  display:flex;
  margin-right:20px;
}
.Navbar__Link {
  font-size:1.4vw;
}
.column {
  height: 100%;  /*allows both columns to span the full height of the browser window*/
  display: flex;
  flex-direction: column;  /*places the left and right headers above the bottom content*/
}
.top{
  background-color: #333;
  padding: 0px 20px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  color: white;
}
.me {
  font-size: 2vw;
}
.bottom {
  flex-grow: 1;  /*ensures that the container will take up the full height of the parent container*/
  overflow-y: auto;  /*adds scroll to this container*/
}
.Navbar__menu {
  background: #333;
  opacity: 0.5;
  width:100%;
  height:46px;
  align-self: flex-start;
  display: none;
  padding:10px;
} 
@media screen and (max-width: 1024px){
  h2 {
    font-size: 40px;
  }
  h3 {
    font-size: 30px;
    margin-top:20px;
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: #61dafb;
  }

  nav {
    opacity: 0.5;
  }
  .byline {
    height: 400px;
    background-color: transparent;
    display: flex;
  }
  p {
    text-decoration: none;
  }
  main {
    display: flex;
    flex-wrap: wrap;
  }
  
  article {
    border-bottom: 2px solid #333;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }


}
@media screen and (max-width: 768px){

  body {
    /* background: #2235e6 url('../img/conduit.jpg'); */
    background: #000 url('../img/_DSC0041.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    /* background-blend-mode: overlay; */
    background-size: contain;
    background-position: 0% -30px;
  }
  .Navbar__Link {
    font-size:3vw;
  }
  .top-left {
    font-size: 3vw;
  }
}

@media screen and (max-width: 415px){
body {
  /* background: #2235e6 url('../img/conduit.jpg'); */
  background: #000 url('../img/_DSC0041.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* background-blend-mode: overlay; */
  background-size: contain;
  background-position: -20px 70px;
}
}  
@media screen and (max-width: 320px){
  body {
    /* background: #2235e6 url('../img/conduit.jpg'); */
    background: #000 url('../img/_DSC0041.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    /* background-blend-mode: overlay; */
    background-size: contain;
    background-position: -20px 70px;
  }
  .home-h2 {
    font-size: 7vw;
}
#home-article {
  margin:auto;
}
  }  