body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  font-family: sans-serif;
  margin: 0;
}

header {
  padding: 60px;
  text-align: center;
  background-color: transparent;
  color: #fff;
}


#home-section {
  display:flex;
  height: 1366px;
  }


#home-article {
  color: #fff;
  font-size: 3vw;
  margin: auto;
  text-align:center;
  border-bottom: none;

}
#home-article p {
  font-size:2.5vw;
  color:#fff;
  display: flex;
  flex-wrap: wrap;
  text-align:center;
}

footer {
  text-align: center;
  padding: 20px;
  background-color: #aaa;
}
.home-h2 {
  font-size: 2.7vw;
  color:#fff;
  text-align:center;
}
.home-h3 {
  font-size: 35px;
  margin-top:20px;
  color:#fff;
  text-align:center;
}
.home-p {
  font-size: 20px;
  margin:20px auto;
  text-align:center;
}

.links {
  margin:auto;
}



.home-contacts img {
  width:35px;
}
#home-article {
  color: #fff;
  font-size: 3vw;
  margin: auto;
  text-align: center;
  border-bottom: none;
  width: 60%;
}
@media (min-width: 1366px){
  #home-section {
    /* height:200px; */
    display:flex;
  }
  #home-article {
    color: #fff;
    font-size: 3vw;
    margin: auto;
    text-align:center;
    border-bottom: none;
    width:60%;
  }
  #home-article p {
    font-size:2.2vw;
    color:#fff;
    display: flex;
    flex-wrap: wrap;
    text-align:center;
  }

}
@media (min-width: 1024px){
  .home p {
    font-size: 20px;
    margin:20px auto;
    text-align:center;
  }

  .home  {
    color: #fff;
    font-size: 50px;
    margin: auto;
    text-align:center;
    border-bottom: none;
  }

}

.home-contact1,.home-contact2, .home-contact3 {
  padding: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
  margin: 10px;
  flex: 1 1;
 
  list-style-type: none;
  padding:0;
}
.home-contact1 img {
  flex: 1 1;
}
.home-contact2 img {
  flex: 1 1;
}
.home-contact3 {
  flex: 1 1;
}
.home-contacts {
  text-decoration:none;
  text-align:center; 
  margin:auto;
  -webkit-padding-start:0;
          padding-inline-start:0;
  display:flex;
}
.home-contacts li a, .home-contacts li a:visited{
color:#fff;
text-align:left;
}


.links {
  margin:auto;
}
.email {
  margin-top:10px;
}
@media screen and (max-width: 768px){
  /* #home-main {
    height:1200px;
  } */
  #home-section  {
    height:1024px;
  }
  .links {
    margin:auto;
 }
  .home {
    margin:auto;
  }
  .home-h2 {
    font-size: 40px;
  }
  .home-h3 {
    font-size: 30px;
    margin-top:20px;
  }
  .home-p {
    font-size: 20px;
    margin:20px auto;
  }
  .home-contact3 {
    margin-left: 0px;
  }
  a {
    font-size:2.5vw;
  }

  nav {
    opacity: 0.5;
  }

  .home-main  {
    color: #fff;
    font-size: 50px;
    text-align:center;
    border-bottom: none;
    margin:auto;
  }

  .home-contacts img {
    width:35px;
  }
  
  p {
    text-decoration: none;
  }
  #home-article {
    width:75%;
  }
  #home-article p {
    font-size:4.5vw;

  }
}
@media screen and (max-width: 480px) and (max-height: 853px){ 
  #home-section  {
    height:853px;
  }

  .home-contacts img {
    width:35px;
  }
  
  p {
    text-decoration: none;
  }
  #home-article {
    width:75%;
  }
  #home-article p {
    font-size:4.5vw;

  }

}

#about-main {
  /* background:  rgb(106, 13, 114) url('../img/synth.jpg'); */
  background: transparent url(/static/media/lp2.8c651d2b.jpg);
  background-attachment: fixed;
  background-blend-mode: overlay;
  background-position:center center;
    background-size: cover;
  height:1366px;
  width:100vw;
  margin:auto;
}
#about-section {

    display:flex;
    height: 1366px;

}
.about-desc {
  padding:5px;
  background:rgba(53, 50, 50, 0.5) ;
  border-radius:10px;
  margin:auto;
  display:flex;
  flex-direction: column;
  width:75%;
}
.avatar  {
  border-radius:50%;


}
.avatar img {
  border-radius:50%;
  height:160px;
  width:160px;
}


p {
  color:#000;
  margin:10px 0;
}

.about {
  display:flex;
  align-items: center;
  justify-content: center;
}
.about-article {
  margin:20% 0 0;
  text-align:center;
  border:0;

}
.about-article p {
  font-size:1.5vw;
  padding:15px;
  margin:auto;
  display:flex;
  color:#fff;
}

.about-main h2 {
  font-size: 2.5vw;
  color:#fff;
}


@media screen and (max-width: 1024px){
  #about-main {
    height:1366px;
  }
}

@media screen and (max-width: 768px){

  #about-main {
    height:1024px;
  }
  header {
    padding:0px!important;
  }
  .about-main h2 {
    font-size: 4.5vw;

    color:#fff;
  }
  h3 {
    font-size: 30px;
    margin-top:10px;
  }
  p {
    font-size: 20px;
    margin-top:10px;
  }
  .about {
    height:600px;
    display:flex;
  }
  .about-article p {
    font-size:3.5vw;
  }
}
@media screen and (max-width: 480px){
  .about-article p {
  font-size:4.5vw;
}

#about-main {
height:853px;
}
.about-desc {
  padding:5px;
  background:rgba(53, 50, 50, 0.5) ;
  border-radius:10px;
  margin:auto;
  display:flex;
  flex-direction: column;
  width:100%;
}
}
  @media screen and (max-width: 360px){
    .about-article p {
    font-size:4.5vw;
  }

  #about-main {
  height:740px;
  }
  .about-desc {
    padding:5px;
    background:rgba(53, 50, 50, 0.5) ;
    border-radius:10px;
    margin:20px auto 0;
    display:flex;
    flex-direction: column;
    width:100%;
  }
  .avatar {
    margin-top:60px;
  }
}

#project-main {
  background:  #444;
  /* background: #666  url('../img/backgroundA.jpg'); */
  background: transparent  url(/static/media/projects-black.9e477858.PNG);
  background: transparent  url(/static/media/lp.53c7011f.jpg);
  background-attachment: fixed;
  background-blend-mode: overlay;
  background-position:center center;
  height:100%;
  background-size: cover;
  width:100vw;
  margin:auto;
}
#project-section {
  margin:auto;
  height:100%;
}

.projImg {
  margin:auto;
}
.projImg img {
  width:640px;
  border-radius:10px;
  margin:auto;
}
.projImg2 img {
  width:200px;
  border-radius:10px;
  display:flex;
}
.project {
  color:#000;
  font-size: 24px;
  margin:auto;
  display: flex;
  flex: 1 1;
  align-items: stretch;
}
.summary {

  padding:20px;
  /* background:rgba(248, 246, 246, 0.4) ; */
  background:transparent;
  border-radius:10px;
  max-width:640px;
  flex:1 1;
  align-items: stretch;
  margin:auto;
}
button {
  width:90px;
  padding:5px 0;
  margin:auto;
  border-radius:10px;

}
.summary p {
  color:#fff;
  margin:10px auto;
}
button a {
  text-decoration: none;
  color:#fff;
  font-weight:bold;
}
.summary button {
  /* min-width: 100px; */
  padding: 5px;
  margin-right:10px;
  border-radius: 10px;
}
button.livelink {
  margin-top:10px;
}
.projectTitle {
  color:#eee;
  font-size:1.8vw;
  margin:80px auto 20px;
}
.projects p {
  color:#000;
}

.techStack {
  margin-top:10px;
  display:flex;
  border-top:2px #000 solid;
  padding:5px 0;
}
.plantObject {
  margin:auto;
  border:10px #fff solid;
}
h2 {
  color:white;
  font-size:2.7vw;
  text-align:center;

}
#project-section h2 {
  color:white;
  font-size:3vw;
  text-align:center;
}
/* h3 {
  color:#000;
  font-size:2vw;
  text-align:center;

} */
h4 {
  color:#fff;
  font-size:1.5vw;
  margin:10px auto;
}
p {
  font-size: 1vw;
  text-align:left;
  color:#000;
}
ul {
  -webkit-padding-start:20px;
          padding-inline-start:20px;

}
li {
  padding:0px 15px;
}
.bold {
  font-weight:bold;
}
.summary {
  max-width:640px;
  flex: 1 1;
  display: flex;
  align-items: stretch;
}
.summary p {
  font-size:1.2vw;
}
@media screen and (max-width: 1366px){
  .summary p {
    font-size:1.8vw;
  }
  
  .project-article {
    max-height:975px;
  }
}
@media screen and (max-width: 1280px){
  .projectTitle {
    font-size:2vw;

  }
  .summary p {
    font-size:1.8vw;
  }
  .project-main {
    height:3000px;
  }
  .project-article {
    max-height:1075px;
  }
  main, article {
    flex-direction: column;
  }
  .summary p {
    font-size:2.1vw;
  }
}
@media screen and (max-width: 1024px){
  .projectTitle {
    font-size:2.6vw;
  }
  main, article{
    flex-direction: column;

  }

  .summary p {
    font-size:2.3vw;
  }


}
@media screen and (max-width: 1024px) and (max-height: 1366px){

}
@media screen and (max-width: 768px){

#project-section h2 {
  color: white;
  font-size: 3.5vw;
  text-align: center;
}

  .projects img {
    width:89vw;
    border-radius:10px;

  }
}
@media screen and (max-width: 640px){

  .projImg img{
    width:100%;
    margin:auto;
  }
}
@media screen and (max-width: 480px) and (max-height: 853px){


  #project-section h2 {
      font-size: 4.5vw;
  }
  h3.projectTitle {
    font-size: 4.0vw;
  }
  .summary p {
    font-size: 3.4vw;
    /* align-self: auto; */
  }
  button a {
    font-size: 3vw;
  }

}

* {
  box-sizing: border-box;
  font-family: sans-serif;
  margin: 0;
}
#contact-main {
  /* background: rgba(204, 204, 204, 0.8)  url('../img/pipes.jpg'); */

  background: transparent  url(/static/media/background.9dc9c890.gif);
  background: transparent url(/static/media/lp2.8c651d2b.jpg);
  background-blend-mode: screen;
  background-position:center center;
  background-size: cover;
  height:1080px;
  max-height:1366px;
  width: 100%;
  display:flex;
}

#contact-section {
  width:70%;
  margin:auto;
}
#contact-article {
  width:80%;
  margin:auto;
  
}
.placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  padding:0 5px;
}

.contact-form input {
  width:90%;

  font-size:1.5vw;
  display:block;
  margin:20px auto auto;
}
.contact-form textarea {

font-size:1.5vw;
width:90%;
height:150px;
display:block;
margin:auto;
}


.contacts button {
  font-size:1vw;
}
.contacts li a img {
  width:40px;
  height:40px;
}


#contact-article {

  color: #fff;
  font-size: 50px;
  margin: auto;
  text-align:center;
  border-bottom: none;
  background:rgba(255, 255, 255, 0.1) ;
  border-radius:15px;
}
#contact-article p {
  font-size:3vw;
  color:#fff;
  display: flex;
  flex-wrap: wrap;
  text-align:center;
  margin:auto;
}

.contact-main h2 {
  color:#fff;

}
.contact a {

}

.contact {
  display:flex;
  align-items: center;
  justify-content: center;
}
.contact input {
  height:35px;
  width:90%;
  display:flex;
  flex-direction: column;
}
.contact textarea {
  height:35px;
  width:90%;
  height:150px;
  display:flex;
  flex-direction: column;
}
.contacts {
  list-style-type: none;
  padding:0;
  display: flex;
  margin-top:20px;
}
.contacts button {
  display:flex;
}
.contacts li {
justify-content: space-between;
margin:auto;
}
button {
  min-width:100px;
  padding:10px 5px;
  margin:auto;
  border-radius:10px;

}
button a {
  text-decoration: none;
  color:#000;
  font-weight:bold;

}
@media screen and (max-width: 1024px){
  #contact-main {
    height: 1366px;
    display:flex;
  }
}
@media screen and (max-width: 768px){

  #contact-main {
    max-height: 1024px;
    display:flex;
  }
  #contact-article p {
    font-size:4.5vw;
  }

  #contact-article {
    margin:20% auto;
    width:100%;
    display:flex;
    height:100%;
  }
  .contact-form input {
    height: 35px;
    width: 90%;
    display: block;
    margin: 20px auto auto;
    font-size:2.5vw;
}

.contact-form textarea {
  height:35px;
  width:90%;
  height:300px;
  display:block;
  margin:auto;
  font-size:2.5vw;

}

ul.contacts {
  list-style-type: none;
  padding:0;
  display: block;
  width:100%;
  margin:auto;
  display:flex;
  align-items: flex-start 
}
.contacts li {

  padding:5px;
}
h3.contactMe {
  color:#ffffff;
  font-size:4.5vw;
}

.contacts li a{
  text-decoration:none;
}
.contacts li a, .contacts li a:visited{
color:#000;
font-size:1.5vw;
margin:auto;
}
#contact-section {
/* 
  align-items: center;
  justify-content: center; */
}
#contact-article {
  color:#000;
  /* padding:20px; */
}
#contact-article  h2 {
  color:#000;
}

.contacts button a{
  font-size:3vw;
}
h3.contactMe {
  color:#ffffff;
  font-size:4.5vw;
}
}
@media screen and (max-width: 480px) and (max-height: 853px) {

  #contact-main {
    max-height: 853px;
    display:flex;
  }
  #contact-article p {
    font-size:4.5vw;
  }

  #contact-article {
    margin:auto;
    width:500px;
    display:flex;
    height:100%;
  }

  .contact-form input {

    height:35px;
    width:90%;
    display:block;
    margin:20px auto auto;

  }
.contact-form textarea {
  height:35px;
  width:90%;
  height:150px;
  display:block;
  margin:auto;
}

.contacts {
  list-style-type: none;
  padding:0;
  display: flex;
}


.contacts li a{
  text-decoration:none;
}
.contacts li a, .contacts li a:visited{
color:#000;
width:400px;
}
#contact-section {

  margin:auto;
}
#contact-article {
  color:#000;
  /* padding:20px; */
}
#contact-article  h2 {
  color:#000;
}

}

@media screen and (max-width: 415px){
  #contact-main {
    max-height:736px;
  }
  #contact-section {
    width:80%;
    margin:auto;
  }
  #contact-article {
    margin: auto;
    width: 100%;
    display: flex;
    
}

}
@media screen and (max-width: 414px) and (max-height: 736px){
  #contact-main {
   max-height:736px;
  }
}
@media screen and (max-width: 375px) and (max-height: 812px){
  #contact-main {
      max-height:812px;
    }
    #contact-section {
      width:80%;
      margin:auto;
    }
    #contact-article {
      margin: auto;
      width: 100%;
      display: flex;
      
  }
  
    }
@media screen and (max-width: 375px) and (max-height: 667px){
 #contact-main {
    max-height:667;
  }
}

@media screen and (max-width: 375px) and (max-height:667px){
  #contact-main {
     max-height:667px;
   }
   #contact-section {
     width:80%;
     margin:auto;
   }
   #contact-article {
     margin: auto;
     width: 100%;
     display: flex;
 }

 }
 @media screen and (max-width: 360px){
 #contact-main {
    max-height:740px;
  }
  #contact-section {
    width:80%;
    margin:auto;
  }
  #contact-article {
    margin: auto;
    width: 100%;
    display: flex;
    
}

}
@media screen and (max-width: 360px)and (max-height: 640px){
  #contact-main {
     max-height:640px;
   }
   #contact-section {
     width:80%;
     margin:auto;
   }
   #contact-article {
     margin: auto;
     width: 100%;
     display: flex;     
 }
  .contacts li {
    padding:0px;
  }
   ul.contacts {
   width:100%;
   }
 }
@media screen and (max-width: 320px){
 #contact-main {
    max-height:568px;
  }
  #contact-section {
    width:280px;
    margin:auto;
  }
  #contact-article {
    margin: auto;
    width: 100%;
    display: flex;
    height: 100%;
}

}
* {
  box-sizing: border-box;
  font-family: sans-serif;
  margin: 0;
}
body {
  /* background: #2235e6 url('../img/conduit.jpg'); */
  background: #000 url(/static/media/_DSC0041.28028844.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* background-blend-mode: overlay; */
  background-position: -10% -100px;
  height:100%;
  width:100vw;
  margin:auto;
}
header {
  padding: 60px;
  text-align: center;
  background-color: transparent;
  color: #fff;
}

nav {
  /* display: flex; */
  background-color: #333;
}

nav a {
  color: white;
  text-decoration: none;
  margin: 20px;
}

nav a:hover {
  color: #333;
  background-color: #fff;
}


main {
  display: flex;
  flex-wrap: wrap;
}

article {
  border-bottom: 2px solid #999;
  padding: 10px;
  margin-bottom: 20px;
}

footer {
  text-align: center;
  padding: 20px;
  background-color: #aaa;
}
/* .Navbar__menu {
  display: none;
} */

#Navbar_links {
  display:flex;
  margin-right:20px;
}
.Navbar__Link {
  font-size:1.4vw;
}
.column {
  height: 100%;  /*allows both columns to span the full height of the browser window*/
  display: flex;
  flex-direction: column;  /*places the left and right headers above the bottom content*/
}
.top{
  background-color: #333;
  padding: 0px 20px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  color: white;
}
.me {
  font-size: 2vw;
}
.bottom {
  flex-grow: 1;  /*ensures that the container will take up the full height of the parent container*/
  overflow-y: auto;  /*adds scroll to this container*/
}
.Navbar__menu {
  background: #333;
  opacity: 0.5;
  width:100%;
  height:46px;
  align-self: flex-start;
  display: none;
  padding:10px;
} 
@media screen and (max-width: 1024px){
  h2 {
    font-size: 40px;
  }
  h3 {
    font-size: 30px;
    margin-top:20px;
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: #61dafb;
  }

  nav {
    opacity: 0.5;
  }
  .byline {
    height: 400px;
    background-color: transparent;
    display: flex;
  }
  p {
    text-decoration: none;
  }
  main {
    display: flex;
    flex-wrap: wrap;
  }
  
  article {
    border-bottom: 2px solid #333;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }


}
@media screen and (max-width: 768px){

  body {
    /* background: #2235e6 url('../img/conduit.jpg'); */
    background: #000 url(/static/media/_DSC0041.28028844.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    /* background-blend-mode: overlay; */
    background-size: contain;
    background-position: 0% -30px;
  }
  .Navbar__Link {
    font-size:3vw;
  }
  .top-left {
    font-size: 3vw;
  }
}

@media screen and (max-width: 415px){
body {
  /* background: #2235e6 url('../img/conduit.jpg'); */
  background: #000 url(/static/media/_DSC0041.28028844.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* background-blend-mode: overlay; */
  background-size: contain;
  background-position: -20px 70px;
}
}  
@media screen and (max-width: 320px){
  body {
    /* background: #2235e6 url('../img/conduit.jpg'); */
    background: #000 url(/static/media/_DSC0041.28028844.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    /* background-blend-mode: overlay; */
    background-size: contain;
    background-position: -20px 70px;
  }
  .home-h2 {
    font-size: 7vw;
}
#home-article {
  margin:auto;
}
  }  
