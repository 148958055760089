#about-main {
  /* background:  rgb(106, 13, 114) url('../img/synth.jpg'); */
  background: transparent url('../img/lp2.jpg');
  background-attachment: fixed;
  background-blend-mode: overlay;
  background-position:center center;
    background-size: cover;
  height:1366px;
  width:100vw;
  margin:auto;
}
#about-section {

    display:flex;
    height: 1366px;

}
.about-desc {
  padding:5px;
  background:rgba(53, 50, 50, 0.5) ;
  border-radius:10px;
  margin:auto;
  display:flex;
  flex-direction: column;
  width:75%;
}
.avatar  {
  border-radius:50%;


}
.avatar img {
  border-radius:50%;
  height:160px;
  width:160px;
}


p {
  color:#000;
  margin:10px 0;
}

.about {
  display:flex;
  align-items: center;
  justify-content: center;
}
.about-article {
  margin:20% 0 0;
  text-align:center;
  border:0;

}
.about-article p {
  font-size:1.5vw;
  padding:15px;
  margin:auto;
  display:flex;
  color:#fff;
}

.about-main h2 {
  font-size: 2.5vw;
  color:#fff;
}


@media screen and (max-width: 1024px){
  #about-main {
    height:1366px;
  }
}

@media screen and (max-width: 768px){

  #about-main {
    height:1024px;
  }
  header {
    padding:0px!important;
  }
  .about-main h2 {
    font-size: 4.5vw;

    color:#fff;
  }
  h3 {
    font-size: 30px;
    margin-top:10px;
  }
  p {
    font-size: 20px;
    margin-top:10px;
  }
  .about {
    height:600px;
    display:flex;
  }
  .about-article p {
    font-size:3.5vw;
  }
}
@media screen and (max-width: 480px){
  .about-article p {
  font-size:4.5vw;
}

#about-main {
height:853px;
}
.about-desc {
  padding:5px;
  background:rgba(53, 50, 50, 0.5) ;
  border-radius:10px;
  margin:auto;
  display:flex;
  flex-direction: column;
  width:100%;
}
}
  @media screen and (max-width: 360px){
    .about-article p {
    font-size:4.5vw;
  }

  #about-main {
  height:740px;
  }
  .about-desc {
    padding:5px;
    background:rgba(53, 50, 50, 0.5) ;
    border-radius:10px;
    margin:20px auto 0;
    display:flex;
    flex-direction: column;
    width:100%;
  }
  .avatar {
    margin-top:60px;
  }
}
